import React from 'react';
import { graphql } from 'gatsby';
import Blog from '@rentivo/gatsby-theme-barcelona/src/templates/Blog/component';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-theme-barcelona/src/templates/BlogCategory/messages';

const BlogCategory = (props) => {
  const { formatMessage } = useIntl();
  const { pageContext: { name, pageNumber }} = props;

  const values = {
    category: name,
    pageNumber
  };

  const seo = {
    seoTitle: formatMessage(messages.seoTitle, values),
    seoMetaDescription: formatMessage(messages.seoMetaDescription, values)
  };

  return <Blog
    {...props}
    showFeatured={false}
    seo={seo}
    title={<FormattedMessage {...messages.pageTitle} values={values}/>}
  />;
};

export default BlogCategory;

export const pageQuery = graphql`
  query BlogCategoryQuery($ids: [String], $databaseId: Int, $localeCode: WpLanguageCodeEnum) {
    allWpCategory(
      limit: 50
      filter: {language: {code: {eq: $localeCode}}, parentDatabaseId: {eq: $databaseId}, postCategory: {dps: {makeDps: {ne: true}}}}
      sort: {order: DESC, fields: count}
    ) {
      nodes {
        id
        name
        slug
        translations {
          id
          name
          slug
          language {
            slug
            locale
            code
          }
        }
        ancestors {
          nodes {
            id
            name
            slug
            translations {
              id
              name
              slug
              language {
                slug
                locale
                code
              }
            }
          }
        }
      }
    }
    allWpPost(limit: 100, filter: {id: {in: $ids}}) {
      nodes {
        ...PostListFields
        translations {
          ...PostListFields
        }
      }
    }
  }
`;
